<template>
  <div id="app">
    <!-- <b-card
      overlay
      img-src="https://picsum.photos/900/250/?image=3"
      img-alt="  Good Stuffgoodstuff.mywebarist.eu "
      text-variant="white"
      title="goodstuff.mywebarist.eu"
      sub-title="Buy nice stuff for nice price"
    >
   
      <b-card-text>
        Buy nice stuff for nice price
      </b-card-text>
      <b-card-text class="text-primary ">
        Buy nice stuff for nice price
      </b-card-text>
      <b-card-text class="text-danger ">
        Buy nice stuff for nice price
      </b-card-text>
    </b-card> -->

    <div>
      <b-navbar toggleable type="dark" variant="dark" class=" ">
        <b-navbar-brand to="/"
          ><b-icon icon="google" type="light" variant="light"></b-icon>ood Stuff
          List</b-navbar-brand
        >

        <b-nav class="m-1">
          <!-- <b-nav-item to="/" active>Sell</b-nav-item> -->
          <!-- <b-button to="/" variant="info"  class="m-1" >Home</b-button> -->
          <b-button to="/sell" variant="success" class="m-1">Sell your stuff</b-button>
          <!-- <b-button v-if="!loggedIn" to="/login" class="m-1">Login</b-button>
          <b-button v-if="!loggedIn" to="/register" class="m-1">Register</b-button> -->

          <b-button v-if="loggedIn" to="/account" class="m-1"
            ><b-icon icon="person-fill" type="light" variant="light"></b-icon
          ></b-button>
          <!-- <b-button v-if="loggedIn" to="/logout" class="m-1">Logout</b-button> -->
        </b-nav>
      </b-navbar>
    </div>

    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <router-view />

    <!-- <b-row class="mb-5"></b-row> -->

    <b-navbar toggleable variant=" " class="bottom  ">
      <b-navbar-brand to="/"></b-navbar-brand>

      <b-nav class="m-1  ">
        <b-link to="/contact" class="m-1 ">contact me for any issues</b-link>
        | website by
        <b-link href="https://www.mywebartist.eu" target="_blank" class="m-1"
          >myWebArtist.eu</b-link
        >
      </b-nav>
    </b-navbar>
  </div>
</template>

<script>
// @ is an alias to /src
import MagicLink from "@/components/MagicLink.vue";

export default {
  name: "Home",
  components: {
    MagicLink,
  },
  data: function() {
    return {
      loggedIn: false,
    };
  },

  mounted: function() {
    this.isUserLoggedIn();
  },
  methods: {
    isUserLoggedIn() {
      if (localStorage.getItem("username")) {
        this.loggedIn = true;
        this.$store.state.username = localStorage.getItem("username");
      }
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
