<template>
  <div>
    <b-container class="bv-example-row">
      <b-row v-if="items.length == 0">
        <b-col class="my-3">
          loading...
        </b-col>
      </b-row>

      <b-row v-if="count">
        <b-col class="mt-3 text-left">items per page:</b-col>
        <b-col class="mt-3 text-left"
          ><b-form-input
            class="w-50"
            v-model="itemsPerPage"
            type="number"
            min="1"
            placeholder=""
            v-on:update="getItems"
          ></b-form-input
        ></b-col>

        <b-col class="mt-3 text-left">
          page<span v-for="(page, i) in pages" v-bind:key="i" class="ml-1">
            <b-link v-bind:to="pageLink(i + 1)" v-on:click="gotoPage(i + 1)">{{ i + 1 }}</b-link>
          </span>
        </b-col>
        <b-col class="mt-3 text-right"> {{ count }} items for sale </b-col>
      </b-row>

      <b-row v-if="count">
        <b-col class="mt-3 text-left">
          <small>
            <i>
              check weekly for updates. put your stuff on. share the link dankje
            </i>
          </small>
        </b-col>
      </b-row>

      <b-row v-if="items.length > 0">
        <b-col sm="12" md="6" lg="4" xl="3" v-for="(item, i) in items" v-bind:key="i" class="my-3">
          <b-card no-body class="overflow-hidden" style="">
            <b-row no-gutters>
              <b-col md="12">
                <b-nav-item v-bind:to="'item/' + item.id">
                  <b-card-img
                    v-if="item.pictures[0]"
                    v-bind:src="serverPath + item.pictures[0].formats.small.url"
                    alt="Image"
                    class="rounded-0 "
                  ></b-card-img>

                  <b-card-img
                    v-else
                    v-bind:src="imageUrl"
                    alt="Image"
                    class="rounded-0"
                  ></b-card-img>
                </b-nav-item>
              </b-col>
              <b-col md="12" to="/1">
                <b-card-body v-bind:title="item.title.slice(0, 30)">
                  <b-card-text>
                    {{ item.description.slice(0, 30) }}
                  </b-card-text>
                </b-card-body>
                <footer>
                  <span v-if="item.price > 0" style="color: red;">
                    {{ item.currencycode }} {{ item.price.toFixed(2) }}</span
                  >
                  <span v-else style="color: red;">FREE</span>
                  <br /><small>
                    <cite title="Source Title"> {{ item.city }}, {{ item.country }} </cite></small
                  >

                  <b-card-footer v-if="item.seller.id == sellerid"
                    ><a v-bind:href="'sell' + '/edit/' + item.id">edit</a></b-card-footer
                  >
                  <b-card-footer v-else></b-card-footer>
                </footer>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>

      <b-row v-if="count">
        <b-col class="mt-3 text-left">
          page<span v-for="(page, i) in pages" v-bind:key="i" class="ml-1">
            <b-link v-bind:to="pageLink(i + 1)" v-on:click="gotoPage(i + 1)">{{ i + 1 }}</b-link>
          </span>
        </b-col>
        <b-col class="mt-3 text-right"> {{ count }} items for sale </b-col>
      </b-row>

      <b-row v-if="count">
        <b-col class="mt-3 text-left">
          <small>
            <i>
              check weekly for updates. put your stuff on. share the link dankje
            </i>
          </small>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Items",
  props: {
    msg: String,
  },

  data: function() {
    return {
      items: [],
      edit: false,
      sellerid: null,
      imageUrl: "/nopic.jpeg",
      serverPath: process.env.VUE_APP_SERVER,
      count: 0,
      itemsPerPage: 40,
      pages: null,
      offset: 0,
      page: null,
    };
  },

  mounted: function() {
    this.sellerid = localStorage.getItem("sellerid");
    // this.getPagination();

    this.page = this.$route.query.page;

    if (this.page) {
      this.gotoPage(this.page);
    } else {
      this.getItems();
    }
  },

  methods: {
    gotoPage(i) {
      this.offset = i * this.itemsPerPage - this.itemsPerPage;

      this.getItems();
    },

    pageLink(i) {
      return "/?page=" + i;
    },

    getPagination() {
      axios.get(process.env.VUE_APP_SERVER + "/items/count?live=true").then((response) => {
        this.count = response.data;
        this.pages = Math.round(this.count / this.itemsPerPage);
      });
    },

    getItems() {
      this.getPagination();
      axios
        .get(
          process.env.VUE_APP_SERVER +
            "/items?live=true&_sort=updated_at:desc&_limit=" +
            this.itemsPerPage +
            "&_start=" +
            this.offset
        )
        .then((response) => {
          this.items = response.data;
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  /* margin: 0 10px; */
}
.nav-link {
  padding: 0;
}
a {
  color: #42b983;
}
</style>
