import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/item/:id",
    name: "ItemView",
    component: () => import(/* webpackChunkName: "about" */ "../views/ItemView.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import(/* webpackChunkName: "about" */ "../views/LoginView.vue"),
  },
  {
    path: "/logout",
    name: "Logout",
    component: () => import(/* webpackChunkName: "about" */ "../views/LogoutView.vue"),
  },
  {
    path: "/account",
    name: "Account",
    component: () => import(/* webpackChunkName: "about" */ "../views/AccountView.vue"),
  },

  {
    path: "/register",
    name: "Register",
    component: () => import(/* webpackChunkName: "about" */ "../views/RegisterView.vue"),
  },
  {
    path: "/sell",
    name: "Sell",
    component: () => import(/* webpackChunkName: "about" */ "../views/SellItemView.vue"),
  },
  {
    path: "/sell/edit/:id",
    name: "Edit",
    component: () => import(/* webpackChunkName: "about" */ "../views/SellItemView.vue"),
  },

  {
    path: "/contact",
    name: "Contact",
    component: () => import(/* webpackChunkName: "about" */ "../views/ContactView.vue"),
  },

  {
    path: "/addlocation",
    name: "AddLocation",
    component: () => import(/* webpackChunkName: "about" */ "../views/AddLocationView.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
