<template>
  <div class="">
    <b-container class="bv-example-row">
      <b-row align-h="start" class="mt-3">
        <b-col cols="" class="text-left">
          <b-icon icon="arrow-left"></b-icon> <b-link to="/">back</b-link>
        </b-col>
      </b-row>
      <b-row class="my-3">
        <b-col>
          <b-form-input v-model="email" placeholder="Enter your email"></b-form-input>
          <b-button class="my-3" variant="success" v-on:click="sendMagicLink"
            >Send Login Link</b-button
          >
        </b-col>
      </b-row>
    </b-container>

    <div>
      <div class="flex items-center justify-center h-screen">
        <div class="hidden sm:block w-1/2 bg-cover h-screen" style="background: url(newFood.png)">
          <div class="bg-blue-800 w-full h-screen bg-opacity-20"></div>
        </div>
        <div class="sm:w-1/2">
          <div class="p-5 w-4/5 mx-auto text-left font-raleway">
            <div class="text-left mb-7">
              <router-link to="/">
                HOME
              </router-link>
            </div>
            <h1 class="font-bold text-left font-montserrat text-4xl sm:text-6xl mb-7">
              Sign Up. To. Join Recipee
            </h1>
            <p v-show="error" class="text-sm text-red-500">{{ errorMsg }}</p>
            <form @submit="register22">
              <div class="my-4">
                <h1 class="text-left font-bold mb-2 font-montserrat">Name</h1>
                <input
                  type="text"
                  v-model="name"
                  class="text-sm outline-none pb-2 w-4/5 bg-transparent border-b hover:border-blue-700 focus:border-blue-700"
                />
              </div>
              <div class="my-4">
                <h1 class="text-left font-bold mb-2 font-montserrat">Email</h1>
                <input
                  type="email"
                  v-model="email"
                  class="text-sm outline-none pb-2 w-4/5 bg-transparent border-b hover:border-blue-700 focus:border-blue-700"
                />
              </div>
              <div class="my-4">
                <h1 class="text-left font-bold mb-2 font-montserrat">Password</h1>
                <input
                  type="password"
                  v-model="password"
                  class="text-sm outline-none pb-2 w-4/5 bg-transparent border-b hover:border-blue-700 focus:border-blue-700"
                />
              </div>
              <div class="my-4">
                <h1 class="text-left font-bold mb-2 font-montserrat">Username</h1>
                <input
                  type="text"
                  v-model="username"
                  class="text-sm outline-none pb-2 w-4/5 bg-transparent border-b hover:border-blue-700 focus:border-blue-700"
                />
              </div>

              <button
                type="submit"
                :disabled="name.length < 1 || password.length < 4 || username.length < 1"
                class=" "
              >
                Sign Up
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "MagicLink",
  props: {
    msg: String,
  },

  data: function() {
    return {
      item: {},
      email: "muqeeti@hotmail.com",
      name: "k",
      password: "simpl3",
      username: "muqeeti",
      error: false,
      errorMsg: `An Error occurred, please try again`,
    };
  },

  mounted: function() {
    // this.sendMagicLink();
  },

  methods: {
    sendMagicLink() {
      var options = {
        identifier: "abc@hotmail.com",
        password: "test",
      };
      axios.post(process.env.VUE_APP_SERVER + "/auth/local", options).then((response) => {
        this.item = response.data;
      });
    },

    register22(event) {
      event.preventDefault();
      var options = {
        name: this.name,
        password: this.password,
        email: this.email,
        username: this.username,
      };
      axios
        .post(process.env.VUE_APP_SERVER + "/auth/local/register", options)
        .then((response) => {
          if (response.error) {
            this.error = true;
            this.errorMsg = response.error;
          }
        })
        .catch((error) => {
          if (error.response) {
          }

          // if (error.response.status == 400) {
          this.error = true;

          this.errorMsg = error.response.data.data[0].messages[0].message;
        });
    },

    async register(e) {
      try {
        e.preventDefault();
        await this.axios.post(`http://localhost:3005/auth/local/register`, {
          name: this.name,
          password: this.password,
          email: this.email,
          username: this.username,
        });
        this.$router.push("login");
      } catch (e) {
        this.error = true;
        // this.email = "";
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  /* margin: 0 10px; */
}
.nav-link {
  padding: 0;
}
a {
  color: #42b983;
}
</style>
